import gql from 'graphql-tag';

import {
  useUpdateTrailerServiceRequestsMutation,
  UpdateTrailerServiceRequestsFields,
  UpdateTrailerServiceRequestsMutation,
  MutationResult,
  ServiceRequestFieldsFragment,
} from '~/services/apollo';

interface UpdateServiceRequestsInput {
  ids: string[];
  replaceWith: UpdateTrailerServiceRequestsFields;
}

const useUpdateTrailerServiceRequests = (): [
  (input: UpdateServiceRequestsInput) => void,
  MutationResult<UpdateTrailerServiceRequestsMutation>,
] => {
  const [updateServiceRequests, statusFields] =
    useUpdateTrailerServiceRequestsMutation();

  const update = async (input: UpdateServiceRequestsInput) => {
    const { ids, replaceWith } = input;

    await updateServiceRequests({
      variables: {
        input: {
          ids,
          replaceWith,
        },
      },
      update(cache, { data }) {
        ids.forEach(id => {
          const cachedServiceRequest =
            cache.readFragment<ServiceRequestFieldsFragment>({
              id: cache.identify({ __typename: 'TrailerServiceRequest', id }),
              fragment:
                useUpdateTrailerServiceRequests.ServiceRequestFieldsFragment,
              fragmentName: 'ServiceRequestFields',
            });
          if (!cachedServiceRequest) {
            throw new Error(
              `Failed to read fragment data for TrailerServiceRequest ${id} from cache`,
            );
          }

          const newServiceRequest =
            data?.updateTrailerServiceRequests?.updatedServiceRequests;
          if (newServiceRequest) {
            cache.writeFragment({
              id: cache.identify({ __typename: 'TrailerServiceRequest', id }),
              fragment:
                useUpdateTrailerServiceRequests.ServiceRequestFieldsFragment,
              fragmentName: 'ServiceRequestFields',
              data: {
                ...cachedServiceRequest,
                ...newServiceRequest,
              },
            });
          }
        });
      },
    });
  };

  return [update, statusFields];
};

useUpdateTrailerServiceRequests.ServiceRequestFieldsFragment = gql`
  fragment ServiceRequestFields on TrailerServiceRequest {
    id
    status
    dueDate
  }
`;

useUpdateTrailerServiceRequests.UpdateServiceRequestsMutation = gql`
  mutation updateTrailerServiceRequests(
    $input: UpdateTrailerServiceRequestsInput!
  ) {
    updateTrailerServiceRequests(input: $input) {
      updatedServiceRequests {
        ...ServiceRequestFields
      }
    }
  }
  ${useUpdateTrailerServiceRequests.ServiceRequestFieldsFragment}
`;

export default useUpdateTrailerServiceRequests;
