import LocationsInfo from '~/components/pages/mappings/LocationsInfo';
import {
  useCreateTrailerShopServiceYardAssignmentMutation,
  ServicingTrailerShopsFragment,
  CreateTrailerShopServiceYardAssignmentMutation,
  MutationResult,
  ServiceProvider,
} from '~/services/apollo';

interface CreateShopYardAssignmentInput {
  shopId: string;
  yardKey: string;
  serviceProvider: ServiceProvider;
}

const useCreateShopYardAssignment = (): [
  (input: CreateShopYardAssignmentInput) => void,
  MutationResult<CreateTrailerShopServiceYardAssignmentMutation>,
] => {
  const [createAssignment, statusFields] =
    useCreateTrailerShopServiceYardAssignmentMutation();

  const createShopYardAssignment = async (
    input: CreateShopYardAssignmentInput,
  ) => {
    const { shopId, yardKey, serviceProvider } = input;

    await createAssignment({
      variables: {
        input: {
          shopId,
          yardKey,
        },
      },
      update(cache, { data }) {
        const cachedTrailerYard =
          cache.readFragment<ServicingTrailerShopsFragment>({
            id: cache.identify({ __typename: 'TrailerYard', key: yardKey }),
            fragment: LocationsInfo.ServicingTrailerShopsFragment,
            fragmentName: 'ServicingTrailerShops',
            variables: {
              serviceProvider,
            },
          });
        if (!cachedTrailerYard) {
          throw new Error(
            `Failed to read fragment data for TrailerYard ${yardKey} from cache`,
          );
        }

        const newShop =
          data?.createTrailerShopServiceYardAssignment
            ?.createdTrailerShopServiceYardAssignment?.trailerShop;
        if (newShop) {
          cache.writeFragment({
            id: cache.identify({ __typename: 'TrailerYard', key: yardKey }),
            fragment: LocationsInfo.ServicingTrailerShopsFragment,
            fragmentName: 'ServicingTrailerShops',
            variables: {
              serviceProvider,
            },
            data: {
              ...cachedTrailerYard,
              trailerShops: [newShop],
            },
          });
        }
      },
    });
  };

  return [createShopYardAssignment, statusFields];
};

export default useCreateShopYardAssignment;
