import React from 'react';

import { gql } from '@apollo/client';
import styled from '@emotion/styled';
import PlaceSharp from '@material-ui/icons/PlaceSharp';
import { toast } from 'react-toastify';

import * as Fuel from '@convoy/fuel';

import { Maybe, useTrailerLatestLocationLazyQuery } from '~/services/apollo';
import { getErrorToDisplay } from '~/utils/errors';

const Button = styled(Fuel.Button)({
  padding: 0,
  height: 'auto',
  ...Fuel.Text.Size.SM,
});

interface Props {
  trailerKey: string;
}

const openPage = (
  long: Maybe<number> | undefined,
  lat: Maybe<number> | undefined,
) => {
  if (!long || !lat) {
    return;
  }
  const googleMapsUrl = `http://maps.google.com/maps?t=k&q=loc:${long},${lat}`;
  window.open(googleMapsUrl);
};

const ViewOnMapLink = ({ trailerKey }: Props) => {
  const [lazyGetLocation, { loading }] = useTrailerLatestLocationLazyQuery({
    variables: {
      trailerKey,
    },
    onCompleted(data) {
      const newCoordinates =
        data?.trailer?.latestLocation?.coordinates?.coordinates;
      openPage(newCoordinates?.[0], newCoordinates?.[1]);
    },
    onError(error) {
      toast.error(getErrorToDisplay(error));
    },
  });

  const handleViewOnMapClick = async () => {
    lazyGetLocation();
  };

  return (
    <Button
      type='link'
      onClick={handleViewOnMapClick}
      spinner={loading}
      disableOnSpin
    >
      <Fuel.Icon icon={PlaceSharp} size='LG' />
    </Button>
  );
};

export default ViewOnMapLink;

ViewOnMapLink.query = gql`
  query TrailerLatestLocation($trailerKey: ID!) {
    trailer(key: $trailerKey) {
      latestLocation {
        coordinates {
          coordinates
        }
      }
    }
  }
`;
