import React, { useState, useEffect, useCallback } from 'react';

import { gql } from '@apollo/client';

import * as Fuel from '@convoy/fuel';
import { update } from '@convoy/lynx.utils';
import { TrailerPoolKey } from '@convoy/trailer-service-types';

import {
  Table,
  TableHeader,
  HeaderCell,
  TableBody,
  TableRow,
  BodyCell,
  SortIcon,
} from '~/components/core/Table';
import { TableSortFilter } from '~/components/core/Table/SortIcon';
import { defaultOnSort } from '~/components/core/Table/SortUtils';
import ViewOnMapLink from '~/components/core/ViewOnMapLink';
import {
  ExternalEntity,
  TrailerInfoFragment,
  useTrailersInfoQuery,
} from '~/services/apollo';
import { compact } from '~/utils/nullish';
import { DEFAULT_EMPTY_VALUE } from '~/utils/strings';

import EditableExternalReferenceCell from './EditableExternalReferenceCell';
import EditableFHWACell from './EditableFHWACell';

const REQUESTED_PAGE_SIZE = 25;

const strings = {
  tableHeaders: {
    convoyNumber: `Convoy #`,
    ryderNumber: `Ryder #`,
    dueDate: `Due Date`,
    vin: `VIN`,
    make: `Make`,
    model: `Model`,
    year: `Year`,
    provider: `Provider`,
    location: `GPS Location`,
  },
};

const TrailersInfo = () => {
  const externalEntity = ExternalEntity.Ryder;
  const [tableSortFilters, setTableSortFilters] = useState<
    Record<string, TableSortFilter>
  >({
    convoyNumber: { fieldPath: 'number', sortDirection: null },
    externalNumber: {
      fieldPath: 'trailerExternalReferences.0.externalId',
      sortDirection: null,
    },
    fhwaDueDate: { fieldPath: 'latestFHWA.dueDate', sortDirection: null },
  });
  const { data, loading, fetchMore } = useTrailersInfoQuery({
    variables: {
      filters: {
        trailerPoolKeys: [TrailerPoolKey.CONVOY_UTP_POOL_KEY],
      },
      first: REQUESTED_PAGE_SIZE,
    },
    fetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  });

  const [trailers, setTrailers] = useState<TrailerInfoFragment[]>([]);

  const lastCursor = data?.trailers?.pageInfo?.lastCursor;
  const loadNextPage = useCallback(async () => {
    await fetchMore({
      variables: {
        after: lastCursor,
      },
      updateQuery(prev, { fetchMoreResult }) {
        if (!fetchMoreResult?.trailers?.edges) return prev;
        return update(prev, {
          trailers: {
            edges: { $push: fetchMoreResult?.trailers?.edges! },
            pageInfo: { $set: fetchMoreResult?.trailers?.pageInfo },
          },
        });
      },
    });
  }, [fetchMore, lastCursor]);

  const hasNextPage = loading || lastCursor != null;
  useEffect(() => {
    if (!loading) {
      setTrailers(compact(data?.trailers?.edges));
    }
  }, [data?.trailers?.edges, loading]);

  const onSort = defaultOnSort(setTableSortFilters, setTrailers);

  return (
    <Table>
      <TableHeader data-el={'Mappings-TrailersInfo-HeaderRow'}>
        <HeaderCell>
          {strings.tableHeaders.convoyNumber}
          <SortIcon
            data={trailers}
            onSort={onSort}
            sortFilter={tableSortFilters.convoyNumber}
          />
        </HeaderCell>
        <HeaderCell>
          {strings.tableHeaders.ryderNumber}
          <SortIcon
            data={trailers}
            onSort={onSort}
            sortFilter={tableSortFilters.externalNumber}
          />
        </HeaderCell>
        <HeaderCell>
          {strings.tableHeaders.dueDate}
          <SortIcon
            data={trailers}
            onSort={onSort}
            sortFilter={tableSortFilters.fhwaDueDate}
          />
        </HeaderCell>
        <HeaderCell>{strings.tableHeaders.vin}</HeaderCell>
        <HeaderCell>{strings.tableHeaders.make}</HeaderCell>
        <HeaderCell>{strings.tableHeaders.model}</HeaderCell>
        <HeaderCell>{strings.tableHeaders.year}</HeaderCell>
        <HeaderCell>{strings.tableHeaders.provider}</HeaderCell>
        <HeaderCell>{strings.tableHeaders.location}</HeaderCell>
      </TableHeader>
      <TableBody>
        <div data-el={'Mappings-TrailersInfo-Body'}>
          <Fuel.PagedList
            height={700}
            rowHeight={95}
            width={'100%'}
            hasNextPage={hasNextPage}
            isNextPageLoading={loading}
            items={trailers as TrailerInfoFragment[]}
            loadNextPage={loadNextPage}
            data-el={'Mappings-TrailersInfo-Body-PagedList'}
          >
            {(_index, trailer) => (
              <TableRow
                key={trailer.key}
                data-el={'Mappings-TrailersInfo-BodyRow'}
              >
                <BodyCell
                  data-el={'Mappings-TrailersInfo-BodyRow-TrailerNumber'}
                >
                  {trailer.number}
                </BodyCell>
                <BodyCell
                  data-el={'Mappings-TrailersInfo-BodyRow-ExternalReference'}
                >
                  <EditableExternalReferenceCell
                    trailer={trailer}
                    externalEntity={externalEntity}
                  />
                </BodyCell>
                <BodyCell data-el={'Mappings-TrailersInfo-BodyRow-FhwaDueDate'}>
                  <EditableFHWACell trailer={trailer} />
                </BodyCell>
                <BodyCell data-el={'Mappings-TrailersInfo-BodyRow-VIN'}>
                  {trailer.vin || DEFAULT_EMPTY_VALUE}
                </BodyCell>
                <BodyCell data-el={'Mappings-TrailersInfo-BodyRow-Make'}>
                  {trailer.make || DEFAULT_EMPTY_VALUE}
                </BodyCell>
                <BodyCell data-el={'Mappings-TrailersInfo-BodyRow-Model'}>
                  {trailer.model || DEFAULT_EMPTY_VALUE}
                </BodyCell>
                <BodyCell data-el={'Mappings-TrailersInfo-BodyRow-Year'}>
                  {trailer.year || DEFAULT_EMPTY_VALUE}
                </BodyCell>
                <BodyCell data-el={'Mappings-TrailersInfo-BodyRow-Provider'}>
                  {trailer.provider}
                </BodyCell>
                <BodyCell data-el={'Mappings-TrailersInfo-BodyRow-Location'}>
                  {trailer.latestLocation?.coordinates?.coordinates?.[0] &&
                  trailer.latestLocation?.coordinates?.coordinates?.[1] ? (
                    <ViewOnMapLink trailerKey={trailer.key!} />
                  ) : (
                    DEFAULT_EMPTY_VALUE
                  )}
                </BodyCell>
              </TableRow>
            )}
          </Fuel.PagedList>
        </div>
      </TableBody>
    </Table>
  );
};

TrailersInfo.ExternalReferenceFragment = gql`
  fragment ExternalReference on TrailerExternalReference {
    id
    trailerKey
    externalEntity
    externalId
  }
`;

TrailersInfo.FHWAFragment = gql`
  fragment FHWA on TrailerServiceRequest {
    id
    type
    trailerKey
    dueDate
  }
`;

TrailersInfo.TrailerInfoFragment = gql`
  fragment TrailerInfo on Trailer {
    id
    key
    number
    vin
    make
    model
    year
    provider
    latestFHWA {
      ...FHWA
    }
    trailerExternalReferences(filters: { externalEntities: [RYDER] }) {
      ...ExternalReference
    }
    latestLocation {
      coordinates {
        coordinates
      }
    }
  }
  ${TrailersInfo.ExternalReferenceFragment}
  ${TrailersInfo.FHWAFragment}
`;

TrailersInfo.query = gql`
  query TrailersInfo(
    $filters: TrailersFilterArgs
    $after: Cursor
    $first: Float
  ) {
    trailers(filters: $filters, after: $after, first: $first) {
      edges {
        ...TrailerInfo
      }
      pageInfo {
        lastCursor
        totalCount
      }
    }
  }
  ${TrailersInfo.TrailerInfoFragment}
`;

export default TrailersInfo;
