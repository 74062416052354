import React, { useState } from 'react';

import styled from '@emotion/styled';
import EditSharp from '@material-ui/icons/EditSharp';
import gql from 'graphql-tag';

import * as Fuel from '@convoy/fuel';

import Modal from '~/components/core/Modal';
import {
  useShopYardAssignmentCreate,
  useShopYardAssignmentUpdate,
} from '~/hooks';
import { ServiceProvider } from '~/services/apollo';
import { DEFAULT_EMPTY_VALUE } from '~/utils/strings';

export type ShopCodeOption = {
  value: string;
  label: string;
};

const EditIcon = styled(Fuel.Icon)({
  marginLeft: Fuel.Unit.XS,
  color: Fuel.Color.Dark.N600,
  ':hover': {
    cursor: 'pointer',
  },
});

const AddShopLink = styled(Fuel.Button)({
  height: 'auto',
  padding: 0,
  ...Fuel.Text.Size.SM,
});

const ModalBody = styled(Fuel.Modal.Body)({
  display: 'flex',
  '>label:nth-of-type(1)': {
    flex: 1,
    marginRight: Fuel.Unit.XL,
  },
  '>label:nth-of-type(2)': {
    flex: 1,
  },
  overflow: 'visible',
});

// TODO(emily.zhai): Generalize Ryder specific strings based on logged in user
const strings = {
  addShop: 'Assign',
  addOrEditLocationShop: 'Add/Edit a Location’s Ryder Shop',
  location: 'Location',
  shop: 'Ryder Shop',
};

interface Props {
  shopCode?: string | null;
  yardKey: string;
  locationCode: string;
  shopCodeOptions: ShopCodeOption[];
}

const EditableShopCodeCell = (props: Props) => {
  const { shopCode, yardKey, locationCode, shopCodeOptions } = props;

  const defaultShopCodeOption = shopCodeOptions.find(
    option => option.label === shopCode,
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedShopCodeOption, setSelectedShopCodeOption] = useState(
    defaultShopCodeOption,
  );

  const [createShopYardAssignment] = useShopYardAssignmentCreate();
  const [updateShopYardAssignment] = useShopYardAssignmentUpdate();

  const openModal = () => {
    setSelectedShopCodeOption(defaultShopCodeOption);
    setIsModalOpen(true);
  };
  const closeModal = () => setIsModalOpen(false);

  const onSelectShopCodeOption = (option: ShopCodeOption) =>
    setSelectedShopCodeOption(option);

  const saveShopAssociation = async () => {
    if (!defaultShopCodeOption) {
      await createShopYardAssignment({
        shopId: selectedShopCodeOption!.value,
        yardKey,
        serviceProvider: ServiceProvider.Ryder,
      });
    } else {
      await updateShopYardAssignment({
        shopId: defaultShopCodeOption!.value,
        yardKey,
        serviceProvider: ServiceProvider.Ryder,
        replaceWith: {
          shopId: selectedShopCodeOption!.value,
        },
      });
    }
    closeModal();
  };

  return (
    <>
      {shopCode ? (
        <>
          {shopCode}
          <EditIcon
            data-el='Mappings-LocationsInfo-editShopCodeBtn'
            icon={EditSharp}
            size='SM'
            onClick={openModal}
          />
        </>
      ) : (
        <AddShopLink
          data-el='Mappings-LocationsInfo-addShopCodeBtn'
          type='link'
          onClick={openModal}
        >
          {strings.addShop}
        </AddShopLink>
      )}

      <Modal
        dataElPrefix='Mappings-LocationsInfo-Modal'
        isOpen={isModalOpen}
        onClose={closeModal}
        title={strings.addOrEditLocationShop}
        actionButtonFunction={saveShopAssociation}
        isActionButtonDisabled={
          selectedShopCodeOption?.value === defaultShopCodeOption?.value
        }
      >
        <ModalBody>
          <Fuel.Label label={strings.location}>
            <Fuel.Input.Text
              data-el='Mappings-LocationsInfo-Modal-locationCodeInput'
              disabled
              placeholder={locationCode}
            />
          </Fuel.Label>
          <Fuel.Label label={strings.shop}>
            <Fuel.Select
              data-el='Mappings-LocationsInfo-Modal-shopCodeInput'
              placeholder={DEFAULT_EMPTY_VALUE}
              value={selectedShopCodeOption}
              onChange={onSelectShopCodeOption}
              options={shopCodeOptions}
            />
          </Fuel.Label>
        </ModalBody>
      </Modal>
    </>
  );
};

EditableShopCodeCell.CreateShopYardAssignmentMutation = gql`
  mutation createTrailerShopServiceYardAssignment(
    $input: CreateTrailerShopServiceYardAssignmentInput!
  ) {
    createTrailerShopServiceYardAssignment(input: $input) {
      createdTrailerShopServiceYardAssignment {
        id
        trailerShop {
          id
          externalId
        }
      }
    }
  }
`;

EditableShopCodeCell.UpdateShopYardAssignmentMutation = gql`
  mutation updateTrailerShopServiceYardAssignment(
    $input: UpdateTrailerShopServiceYardAssignmentInput!
  ) {
    updateTrailerShopServiceYardAssignment(input: $input) {
      updatedTrailerShopServiceYardAssignment {
        id
        trailerShop {
          id
          externalId
        }
      }
    }
  }
`;

export default EditableShopCodeCell;
