import React, { useState, useEffect } from 'react';

import gql from 'graphql-tag';
import { DateTime } from 'luxon';

import * as Fuel from '@convoy/fuel';
import { TrailerPoolKey } from '@convoy/trailer-service-types';

import {
  Table,
  TableHeader,
  HeaderCell,
  TableBody,
  TableRow,
  BodyCell,
  SortIcon,
} from '~/components/core/Table';
import { TableSortFilter } from '~/components/core/Table/SortIcon';
import { defaultOnSort } from '~/components/core/Table/SortUtils';
import {
  useTrailerShopCodesQuery,
  useGetServiceYardsQuery,
  ServiceProvider,
} from '~/services/apollo';
import { compact } from '~/utils/nullish';
import { DEFAULT_EMPTY_VALUE } from '~/utils/strings';

import EditableShopCodeCell from './EditableShopCodeCell';

type TrailerShopServiceYardRow = {
  yardKey: string;
  shopCode?: string | null;
  locationCode: string;
  locationName: string;
  address: string;
  timezone: string;
  restrictions: string;
};

const strings = {
  shop: 'Shop',
  locationCode: 'Location Code',
  locationName: 'Location Name',
  address: 'Address',
  timezone: 'Time Zone',
  restrictions: 'Restrictions',
};

const LocationsInfo = () => {
  const [shopServiceYards, setShopServiceYards] = useState<
    TrailerShopServiceYardRow[]
  >([]);
  const [tableSortFilters, setTableSortFilters] = useState<
    Record<string, TableSortFilter>
  >({
    shopCode: { fieldPath: 'shopCode', sortDirection: null },
    locationName: { fieldPath: 'locationName', sortDirection: null },
    locationCode: { fieldPath: 'locationCode', sortDirection: null },
    timezone: { fieldPath: 'timezone', sortDirection: null },
  });

  // TODO: Parameterize which serviceProvider's data is being requested based
  // on current user
  const { data: trailerShopCodeData } = useTrailerShopCodesQuery({
    variables: {
      serviceProvider: ServiceProvider.Ryder,
    },
  });
  const shopCodeOptions = compact(trailerShopCodeData?.trailerShops)
    .sort((a, b) =>
      a.externalId.toUpperCase() > b.externalId.toUpperCase() ? 1 : -1,
    )
    .map(shop => ({
      label: shop.externalId,
      value: shop.id,
    }));

  const { loading, data: serviceYardData } = useGetServiceYardsQuery({
    variables: {
      serviceProvider: ServiceProvider.Ryder,
      trailerPoolKeys: [TrailerPoolKey.CONVOY_UTP_POOL_KEY],
    },
  });

  useEffect(() => {
    const shopServiceYardRows = compact(
      serviceYardData?.trailerYards?.edges,
    ).map(yard => ({
      yardKey: yard.key,
      // For now, there will only be one shop assigned to a yard per provider
      shopCode: yard.trailerShops?.[0]?.externalId,
      locationCode: yard.locationCode || DEFAULT_EMPTY_VALUE,
      locationName: yard.name || DEFAULT_EMPTY_VALUE,
      address:
        yard.parcel.primaryLocationProfile?.address?.full ||
        DEFAULT_EMPTY_VALUE,
      timezone: yard.parcel.primaryLocationProfile?.timezone
        ? DateTime.fromJSDate(new Date(), {
            zone: yard.parcel.primaryLocationProfile!.timezone!,
          }).toFormat('ZZZZ')
        : DEFAULT_EMPTY_VALUE,
      restrictions: yard.yardRestrictions?.opsNote || DEFAULT_EMPTY_VALUE,
    }));
    setShopServiceYards(shopServiceYardRows);
  }, [serviceYardData]);

  const onSort = defaultOnSort(setTableSortFilters, setShopServiceYards);

  if (loading) {
    return <Fuel.Spinner data-el='Mappings-LocationsInfo-loading' size='XXL' />;
  }

  return (
    <>
      <Table>
        <TableHeader data-el='Mappings-LocationsInfo-headerRow'>
          <HeaderCell
            css={{
              flex: 1,
              paddingLeft: Fuel.Unit.MD,
            }}
          >
            {strings.shop}
            <SortIcon
              data={shopServiceYards}
              onSort={onSort}
              sortFilter={tableSortFilters.shopCode}
            />
          </HeaderCell>
          <HeaderCell css={{ flex: 1.5 }}>
            {strings.locationCode}
            <SortIcon
              data={shopServiceYards}
              onSort={onSort}
              sortFilter={tableSortFilters.locationCode}
            />
          </HeaderCell>
          <HeaderCell css={{ flex: 2 }}>
            {strings.locationName}
            <SortIcon
              data={shopServiceYards}
              onSort={onSort}
              sortFilter={tableSortFilters.locationName}
            />
          </HeaderCell>
          <HeaderCell css={{ flex: 3 }}>{strings.address}</HeaderCell>
          <HeaderCell css={{ flex: 1 }}>
            {strings.timezone}
            <SortIcon
              data={shopServiceYards}
              onSort={onSort}
              sortFilter={tableSortFilters.timezone}
            />
          </HeaderCell>
          <HeaderCell css={{ flex: 6 }}>{strings.restrictions}</HeaderCell>
        </TableHeader>
        <TableBody>
          {shopServiceYards.map(shopServiceYard => (
            <TableRow
              data-el='Mappings-LocationsInfo-bodyRow'
              key={shopServiceYard.yardKey}
            >
              <BodyCell
                css={{
                  flex: 1,
                  paddingLeft: Fuel.Unit.MD,
                }}
              >
                <EditableShopCodeCell
                  shopCode={shopServiceYard.shopCode}
                  yardKey={shopServiceYard.yardKey}
                  locationCode={shopServiceYard.locationCode}
                  shopCodeOptions={shopCodeOptions}
                />
              </BodyCell>
              <BodyCell css={{ flex: 1.5 }}>
                {shopServiceYard.locationCode}
              </BodyCell>
              <BodyCell css={{ flex: 2 }}>
                {shopServiceYard.locationName}
              </BodyCell>
              <BodyCell css={{ flex: 3 }}>{shopServiceYard.address}</BodyCell>
              <BodyCell css={{ flex: 1 }}>{shopServiceYard.timezone}</BodyCell>
              <BodyCell css={{ flex: 6 }}>
                {shopServiceYard.restrictions}
              </BodyCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

LocationsInfo.ServicingTrailerShopsFragment = gql`
  fragment ServicingTrailerShops on TrailerYard {
    id
    key
    trailerShops(serviceProvider: $serviceProvider) {
      id
      externalId
    }
  }
`;

LocationsInfo.query = gql`
  query trailerShopCodes($serviceProvider: ServiceProvider!) {
    trailerShops(serviceProvider: $serviceProvider) {
      id
      externalId
    }
  }
`;

LocationsInfo.query = gql`
  query getServiceYards(
    $serviceProvider: ServiceProvider!
    $trailerPoolKeys: [ID]
  ) {
    trailerYards(trailerPoolKeys: $trailerPoolKeys) {
      edges {
        id
        key
        locationCode
        name
        parcel {
          primaryLocationProfile {
            address {
              full
            }
            timezone
          }
        }
        yardRestrictions {
          opsNote
        }
        ...ServicingTrailerShops
      }
    }
  }
  ${LocationsInfo.ServicingTrailerShopsFragment}
`;

export default LocationsInfo;
