import React from 'react';

import { TableSortFilter } from '~/components/core/Table/SortIcon';
import { SortDirection } from '~/services/apollo';

export function defaultOnSort<T>(
  setSortFilterFn: React.Dispatch<
    React.SetStateAction<Record<string, TableSortFilter>>
  >,
  setContentFn: React.Dispatch<React.SetStateAction<T[]>>,
): (sortedRows: T[], sortedBy: string, sortDirection: SortDirection) => void {
  return (sortedRows: T[], sortedBy: string, sortDirection: SortDirection) => {
    setSortFilterFn(prevSortFilters => {
      const newSortFilters: Record<string, TableSortFilter> = {};
      Object.keys(prevSortFilters).forEach(key => {
        if (
          (prevSortFilters[key].fieldAlias ??
            prevSortFilters[key].fieldPath) === sortedBy
        ) {
          newSortFilters[key] = { ...prevSortFilters[key], sortDirection };
        } else {
          // Only allows table to be sorted by one column at a time
          newSortFilters[key] = {
            ...prevSortFilters[key],
            sortDirection: null,
          };
        }
      });
      return newSortFilters;
    });
    setContentFn(sortedRows);
  };
}
