import React from 'react';

import styled from '@emotion/styled';
import ArrowDropDownSharp from '@material-ui/icons/ArrowDropDownSharp';
import ArrowDropUpSharp from '@material-ui/icons/ArrowDropUpSharp';

import * as Fuel from '@convoy/fuel';

import { SortDirection } from '~/services/apollo';

/**
 * fieldPath: the path to the field to be sorted on
 * sortDirection: ASC or DESC
 * fieldAlias: required when multiple sort columns are relying on the same field Path
 * customMapFn: optional map function, allowing sorting on the mapped value of the specified field
 */
export type TableSortFilter = {
  fieldPath: string;
  sortDirection: SortDirection | null;
  fieldAlias?: string;
  customMapFn?: (a: any) => any;
};

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

const SortArrow = styled(Fuel.Icon)({
  height: 6,
  cursor: 'pointer',
});

export interface Props {
  // Client-side sorting is extremely non-performant and becomes a barrier to
  // pagination once implemented. Eventually, we should remove the default
  // client-side logic to make the decision more difficult and intentional. Use
  // this flag until we are able to transition views that use this component.
  isServerSort?: boolean;
  data: NonNullable<any>[];
  onSort: (
    data: any[],
    sortedBy: string,
    sortDirection: SortDirection,
    fieldAlias?: string,
  ) => void;
  sortFilter: TableSortFilter;
}

const SortIcon = (props: Props) => {
  const { isServerSort, data, onSort, sortFilter } = props;
  const {
    fieldPath: sortBy,
    sortDirection,
    customMapFn,
    fieldAlias,
  } = sortFilter;

  const onSortAsc = () => {
    if (isServerSort) {
      onSort([...data], sortBy, SortDirection.Asc, fieldAlias);
      return;
    }

    const sortedData = [...data].sort((a, b) => {
      const aVal = findNestedValue(a, sortBy, customMapFn);
      const bVal = findNestedValue(b, sortBy, customMapFn);

      // By default, null/undefined is always sorted to the end of a list.
      // Override this behavior and sort to the front of list when ASC.
      if (aVal && !bVal) {
        return 1;
      }

      // Ignore case if sorting strings
      if (typeof aVal === 'string') {
        return aVal.toUpperCase() > bVal.toUpperCase() ? 1 : -1;
      }

      // Javascript considers more recent dates to be greater than less recent
      // dates so they needed to be sorted opposite to the order other data
      // types are sorted such that less recent dates rise to the top when ASC,
      // as is convention.
      if (aVal instanceof Date) {
        return aVal > bVal ? -1 : 1;
      }

      return aVal > bVal ? 1 : -1;
    });
    onSort(sortedData, fieldAlias ?? sortBy, SortDirection.Asc);
  };

  const onSortDesc = () => {
    if (isServerSort) {
      onSort([...data], sortBy, SortDirection.Desc, fieldAlias);
      return;
    }

    const sortedData = [...data].sort((a, b) => {
      const aVal = findNestedValue(a, sortBy, customMapFn);
      const bVal = findNestedValue(b, sortBy, customMapFn);

      // Reinforce the default behavior where null/undefined is always sorted to
      // the end of a list when DESC.
      if (aVal && !bVal) {
        return -1;
      }

      // Ignore case if sorting strings
      if (typeof aVal === 'string') {
        return aVal.toUpperCase() > bVal.toUpperCase() ? -1 : 1;
      }

      // Javascript considers more recent dates to be greater than less recent
      // dates so they needed to be sorted opposite to the order other data
      // types are sorted such that more recent dates rise to the top when DESC,
      // as is convention.
      if (aVal instanceof Date) {
        return aVal > bVal ? 1 : -1;
      }

      return aVal > bVal ? -1 : 1;
    });
    onSort(sortedData, fieldAlias ?? sortBy, SortDirection.Desc);
  };

  // If sort direction is not set yet, show both arrows. Each arrow should sort
  // the list in its corresponding direction (up = ASC | down = DESC) on click.
  if (!sortDirection) {
    return (
      <Container>
        <SortArrow
          data-el='SortIcon-upArrow'
          icon={ArrowDropUpSharp}
          onClick={onSortAsc}
        />
        <SortArrow
          data-el='SortIcon-downArrow'
          icon={ArrowDropDownSharp}
          onClick={onSortDesc}
        />
      </Container>
    );
  }

  // If sort direction has been set, show the arrow that corresponds to the
  // current sort direction. The arrow should flip to the opposite sort
  // direction on click.
  return (
    <Container>
      {sortDirection === SortDirection.Asc ? (
        <SortArrow
          data-el='SortIcon-upArrow'
          icon={ArrowDropUpSharp}
          onClick={onSortDesc}
        />
      ) : (
        <SortArrow
          data-el='SortIcon-downArrow'
          icon={ArrowDropDownSharp}
          onClick={onSortAsc}
        />
      )}
    </Container>
  );
};

const findNestedValue = (
  nestedData: any,
  sortByFields: string,
  customMapFn?: (a: any) => any,
) => {
  if (!Object.keys(nestedData).length) {
    return nestedData;
  }

  let value = nestedData;

  sortByFields.split('.').forEach(sortByField => {
    value = (value || {})[sortByField];
  });
  return customMapFn ? customMapFn(value) : value;
};

export default SortIcon;
