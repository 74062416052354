import { useState, FormEvent } from 'react';

import { gql } from '@apollo/client';
import styled from '@emotion/styled';
import EditSharp from '@material-ui/icons/EditSharp';

import * as Fuel from '@convoy/fuel';

import Modal from '~/components/core/Modal';
import {
  useCreateExternalReference,
  useUpdateExternalReference,
} from '~/hooks';
import { ExternalEntity, TrailerInfoFragment } from '~/services/apollo';

const externalReferenceMaxLength = 20;
const inputRegexCharacter = /[a-zA-Z0-9\\-]/g;
const inputRegexWholeText = /^[a-zA-Z0-9\\-]*$/;

const strings = {
  titlePrefix: 'Add/Edit a Ryder Number for',
  inputLabel: 'Ryder Trailer Number*',
  addButtonText: 'Add #',
  inputStatusHelper: `Input must only contain letters, numbers, dashes '-', and less than ${externalReferenceMaxLength} characters.`,
};

const EditIcon = styled(Fuel.Icon)({
  marginLeft: Fuel.Unit.XS,
  color: Fuel.Color.Dark.N600,
  ':hover': {
    cursor: 'pointer',
  },
});

const AddLink = styled(Fuel.Button)({
  padding: 0,
  height: 'auto',
  ...Fuel.Text.Size.SM,
});

const InputText = styled(Fuel.Input.Text)({
  width: '100%',
});

interface Props {
  trailer: TrailerInfoFragment;
  externalEntity: ExternalEntity;
}

const EditableExternalReferenceCell = (props: Props) => {
  const noInputError = {
    hasError: false,
    userErrorMessage: '',
  };
  const { trailer, externalEntity } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputError, setInputError] = useState(noInputError);

  const defaultExternalId =
    trailer.trailerExternalReferences[0]?.externalId || '';
  const [externalId, setExternalId] = useState(defaultExternalId);

  const handleModalOpen = () => {
    // reset the external reference to match the trailer's
    setExternalId(defaultExternalId);
    // reset the input status and error message
    setInputError(noInputError);

    // open the modal
    setIsModalOpen(true);
  };
  const handleCloseModal = () => setIsModalOpen(false);

  const [updateFunction] = useUpdateExternalReference();
  const [createFunction] = useCreateExternalReference();
  const handleOnSave = async () => {
    if (!externalId) return; // TODO: handle this better
    if (trailer.trailerExternalReferences.length > 0) {
      // update
      await updateFunction({ newExternalId: externalId, trailer });
    } else {
      // create
      await createFunction({
        newExternalId: externalId,
        externalEntity,
        trailer,
      });
    }
  };

  const onExternalIdChanged = (changeEvent: FormEvent<HTMLInputElement>) => {
    const updatedValue = changeEvent.currentTarget.value.trim();
    setExternalId(updatedValue);
    if (!inputRegexWholeText.test(updatedValue)) {
      const invalidChars = updatedValue.replace(inputRegexCharacter, '');
      setInputError({
        hasError: true,
        userErrorMessage: `'${invalidChars}' not a valid input. ${strings.inputStatusHelper}`,
      });
    } else {
      setInputError(noInputError);
      setExternalId(updatedValue);
    }
  };

  return (
    <div>
      {trailer?.trailerExternalReferences.length > 0 ? (
        <div>
          {trailer.trailerExternalReferences[0]!.externalId}
          <EditIcon
            data-el={
              'Mappings-TrailersInfo-EditableExternalReferenceCell-EditIcon'
            }
            icon={EditSharp}
            type='subtle'
            size='SM'
            onClick={handleModalOpen}
          />
        </div>
      ) : (
        <AddLink
          type='link'
          onClick={handleModalOpen}
          data-el={
            'Mappings-TrailersInfo-EditableExternalReferenceCell-AddLink'
          }
        >
          {strings.addButtonText}
        </AddLink>
      )}

      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        title={`${strings.titlePrefix} ${trailer.number}`}
        size={'MD'}
        actionButtonFunction={handleOnSave}
        isActionButtonDisabled={
          inputError.hasError || externalId === defaultExternalId
        }
        dataElPrefix={
          'Mappings-TrailersInfo-EditableExternalReferenceCell-Modal'
        }
      >
        <Fuel.Label
          label={strings.inputLabel}
          data-el={
            'Mappings-TrailersInfo-EditableExternalReferenceCell-Modal-InputLabel'
          }
        >
          <InputText
            data-el={
              'Mappings-TrailersInfo-EditableExternalReferenceCell-Modal-Input'
            }
            placeholder={strings.inputLabel}
            value={externalId ?? ''}
            maxLength={externalReferenceMaxLength}
            onChange={onExternalIdChanged}
            helper={strings.inputStatusHelper}
            status={inputError.hasError ? 'error' : 'default'}
            statusHelper={inputError.userErrorMessage ?? ''}
          />
        </Fuel.Label>
      </Modal>
    </div>
  );
};

EditableExternalReferenceCell.updateExternalReferenceMutation = gql`
  mutation updateExternalReference(
    $input: UpdateTrailerExternalReferenceInput!
  ) {
    updateTrailerExternalReference(input: $input) {
      trailerExternalReference {
        id
        trailerKey
        externalEntity
        externalId
      }
    }
  }
`;

EditableExternalReferenceCell.createExternalReferenceMutation = gql`
  mutation createExternalReference(
    $input: CreateTrailerExternalReferenceInput!
  ) {
    createTrailerExternalReference(input: $input) {
      trailerExternalReference {
        id
        trailerKey
        externalEntity
        externalId
      }
    }
  }
`;

export default EditableExternalReferenceCell;
