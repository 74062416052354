import TrailersInfo from '~/components/pages/mappings/TrailersInfo';
import * as GraphQL from '~/services/apollo';
import {
  CreateExternalReferenceMutation,
  CreateTrailerExternalReferenceInput,
  ExternalEntity,
  TrailerExternalReference,
  TrailerInfoFragment,
  MutationResult,
} from '~/services/apollo';

// custom hook - returns a function that will perform the creation and update
// apollo cache to include the newly created external reference
const useCreateExternalReference = (): [
  (params: {
    newExternalId: string;
    externalEntity: ExternalEntity;
    trailer: Pick<TrailerInfoFragment, 'key' | 'id'>;
  }) => Promise<TrailerExternalReference>,
  MutationResult<CreateExternalReferenceMutation>,
] => {
  const [createFunction, statusFields] =
    GraphQL.useCreateExternalReferenceMutation();
  // the returned function signature:
  // newExternalId - the new external id to be created
  // externalEntity - the externalEntity
  // trailer - the trailer this newExternalReference belongs to
  // returns the newly created externalReference
  const returnFunction = async (params: {
    newExternalId: string;
    externalEntity: ExternalEntity;
    trailer: Pick<TrailerInfoFragment, 'key' | 'id'>;
  }): Promise<TrailerExternalReference> => {
    const { newExternalId, externalEntity, trailer } = params;
    const input: CreateTrailerExternalReferenceInput = {
      trailerKey: trailer.key!,
      externalEntity,
      externalId: newExternalId,
    };
    const { data } = await createFunction({
      variables: {
        input,
      },
      update(cache, { data }) {
        cache.modify({
          id: cache.identify(trailer),
          fields: {
            trailerExternalReferences(existingExternalReferences = []) {
              const newExternalReferenceRef = cache.writeFragment({
                data: data?.createTrailerExternalReference
                  .trailerExternalReference,
                fragment: TrailersInfo.ExternalReferenceFragment,
              });
              return [...existingExternalReferences, newExternalReferenceRef];
            },
          },
        });
      },
    });
    if (!data?.createTrailerExternalReference.trailerExternalReference) {
      throw new Error(
        `Error creating new external reference for request ${JSON.stringify(
          input,
        )}`,
      );
    }
    return data?.createTrailerExternalReference.trailerExternalReference;
  };
  return [returnFunction, statusFields];
};

export default useCreateExternalReference;
