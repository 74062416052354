import LocationsInfo from '~/components/pages/mappings/LocationsInfo';
import {
  useUpdateTrailerShopServiceYardAssignmentMutation,
  ServicingTrailerShopsFragment,
  UpdateTrailerShopServiceYardAssignmentMutation,
  MutationResult,
  ServiceProvider,
} from '~/services/apollo';

interface UpdateShopYardAssignmentInput {
  shopId: string;
  yardKey: string;
  replaceWith: {
    shopId?: string;
    yardKey?: string;
  };
  serviceProvider: ServiceProvider;
}

const useUpdateShopYardAssignment = (): [
  (input: UpdateShopYardAssignmentInput) => void,
  MutationResult<UpdateTrailerShopServiceYardAssignmentMutation>,
] => {
  const [updateAssignment, statusFields] =
    useUpdateTrailerShopServiceYardAssignmentMutation();

  const updateShopYardAssignment = async (
    input: UpdateShopYardAssignmentInput,
  ) => {
    const { shopId, yardKey, replaceWith, serviceProvider } = input;

    await updateAssignment({
      variables: {
        input: {
          shopId,
          yardKey,
          replaceWith,
        },
      },
      update(cache, { data }) {
        const cachedTrailerYard =
          cache.readFragment<ServicingTrailerShopsFragment>({
            id: cache.identify({ __typename: 'TrailerYard', key: yardKey }),
            fragment: LocationsInfo.ServicingTrailerShopsFragment,
            fragmentName: 'ServicingTrailerShops',
            variables: {
              serviceProvider,
            },
          });
        if (!cachedTrailerYard) {
          throw new Error(
            `Failed to read fragment data for TrailerYard ${yardKey} from cache`,
          );
        }

        const newShop =
          data?.updateTrailerShopServiceYardAssignment
            ?.updatedTrailerShopServiceYardAssignment?.trailerShop;
        if (newShop) {
          cache.writeFragment({
            id: cache.identify({ __typename: 'TrailerYard', key: yardKey }),
            fragment: LocationsInfo.ServicingTrailerShopsFragment,
            fragmentName: 'ServicingTrailerShops',
            variables: {
              serviceProvider,
            },
            data: {
              ...cachedTrailerYard,
              trailerShops: [newShop],
            },
          });
        }
      },
    });
  };

  return [updateShopYardAssignment, statusFields];
};

export default useUpdateShopYardAssignment;
