import React, { useState } from 'react';

import styled from '@emotion/styled';
import CloseSharp from '@material-ui/icons/CloseSharp';

import * as Fuel from '@convoy/fuel';
import { ModalProps } from '@convoy/fuel.overlay/Modal/Modal';

import { getErrorToDisplay } from '~/utils/errors';

const ModalContainer = styled.div(
  (props: { isDisabled: boolean }) =>
    props.isDisabled && { pointerEvents: 'none', opacity: 0.5 },
);

const ModalHeader = styled(Fuel.Modal.Header)({
  display: 'flex',
  justifyContent: 'space-between',
});

const ModalBody = styled(Fuel.Modal.Body)({
  overflow: 'visible',
});

const ModalFooter = styled(Fuel.Modal.Footer)({
  display: 'flex',
  justifyContent: 'flex-end',
});

const CloseIcon = styled(Fuel.Icon)({
  color: Fuel.Color.Dark.N600,
  ':hover': {
    cursor: 'pointer',
  },
});

const SubmitError = styled(Fuel.Alert)({
  marginBottom: Fuel.Unit.MD,
});

const ModalButton = styled(Fuel.Button)({
  marginLeft: Fuel.Unit.LG,
  width: Fuel.Unit.XXXL,
});

const strings = {
  cancel: 'Cancel',
  save: 'Save',
};

interface Props extends ModalProps {
  title: string;
  actionButtonFunction: () => Promise<void>;
  actionButtonText?: string;
  isActionButtonDisabled?: boolean;
  dataElPrefix?: string;
}

const Modal = (props: Props) => {
  // Default props from Fuel.Modal
  const { isOpen, onClose, children, size } = props;

  // Custom props
  const { title, actionButtonFunction, dataElPrefix, actionButtonText } = props;

  const [inProgress, setInProgress] = useState(false);
  const [submitError, setSubmitError] = useState<string>();

  const handleClose = () => {
    onClose();
    setSubmitError(undefined);
  };

  const handleAction = async () => {
    try {
      setInProgress(true);
      await actionButtonFunction();
      setInProgress(false);
      handleClose();
    } catch (error) {
      const displayError = getErrorToDisplay(error);
      setSubmitError(displayError);
      setInProgress(false);
    }
  };

  return (
    <Fuel.Modal isOpen={isOpen} onClose={handleClose} size={size ?? 'SM'}>
      <ModalContainer
        isDisabled={inProgress}
        data-el={`${dataElPrefix ?? 'Modal'}-Container`}
      >
        <ModalHeader data-el={`${dataElPrefix ?? 'Modal'}-Header`}>
          <div data-el={`${dataElPrefix ?? 'Modal'}-Header-Title`}>{title}</div>
          <CloseIcon
            icon={CloseSharp}
            onClick={handleClose}
            type={'subtle'}
            data-el={`${dataElPrefix ?? 'Modal'}-Header-CloseIcon`}
          />
        </ModalHeader>
        <SubmitError status='error' isOpen={!!submitError}>
          {submitError}
        </SubmitError>
        <ModalBody data-el={`${dataElPrefix ?? 'Modal'}-Body`}>
          {children}
        </ModalBody>
        <ModalFooter data-el={`${dataElPrefix ?? 'Modal'}-Footer`}>
          <ModalButton
            onClick={handleClose}
            data-el={`${dataElPrefix ?? 'Modal'}-Footer-CancelButton`}
          >
            {strings.cancel}
          </ModalButton>
          <ModalButton
            disabled={props.isActionButtonDisabled}
            onClick={handleAction}
            type='primary'
            disableOnSpin
            spinner={inProgress}
            data-el={`${dataElPrefix ?? 'Modal'}-Footer-ActionButton`}
          >
            {actionButtonText ?? strings.save}
          </ModalButton>
        </ModalFooter>
      </ModalContainer>
    </Fuel.Modal>
  );
};

export default Modal;
