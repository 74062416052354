import { useState } from 'react';

import { gql } from '@apollo/client';
import styled from '@emotion/styled';
import CalendarTodaySharp from '@material-ui/icons/CalendarTodaySharp';
import EditSharp from '@material-ui/icons/EditSharp';
// disabling this eslint check because we don't want to explicitly depend on
// moment. We want to use luxon instead. We have to use moment here because
// Fuel.Input.Date requires moment.
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone';

import * as Fuel from '@convoy/fuel';

import Modal from '~/components/core/Modal';
import { useCreateFHWA, useUpdateTrailerServiceRequests } from '~/hooks';
import { TrailerInfoFragment } from '~/services/apollo';

// we'll need to handle locale and timezone better when we expand to more regions
const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
const dateFormat = 'MM/DD/YYYY';

const strings = {
  titlePrefix: 'Add/Edit FHWA Date for',
  inputLabel: 'FHWA Date',
  inputPlaceHolderText: 'Enter a date',
  addButtonText: 'Set Date',
  actionButtons: {
    cancel: 'Cancel',
    save: 'Save',
  },
};

const EditIcon = styled(Fuel.Icon)({
  marginLeft: Fuel.Unit.XS,
  color: Fuel.Color.Dark.N600,
  ':hover': {
    cursor: 'pointer',
  },
});

const AddLink = styled(Fuel.Button)({
  padding: 0,
  height: 'auto',
  ...Fuel.Text.Size.SM,
});

interface Props {
  trailer: TrailerInfoFragment;
}

const EditableFHWACell = (props: Props) => {
  const { trailer } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newFhwaDueDate, setNewFhwaDueDate] = useState<moment.Moment | null>();

  const defaultDueDate = trailer.latestFHWA?.dueDate
    ? moment(trailer.latestFHWA?.dueDate)
    : null;

  const handleOpenModal = () => {
    setNewFhwaDueDate(defaultDueDate);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => setIsModalOpen(false);

  const [createFunction] = useCreateFHWA();
  const [updateServiceRequest] = useUpdateTrailerServiceRequests();
  const handleOnSave = async () => {
    if (trailer.latestFHWA) {
      const fhwaId = trailer.latestFHWA.id;
      await updateServiceRequest({
        ids: [fhwaId],
        replaceWith: {
          dueDate: newFhwaDueDate?.toDate(),
        },
      });
    } else {
      await createFunction({ newFhwa: newFhwaDueDate!.toDate(), trailer });
    }
  };

  const handleOnDateChanged = (input: {
    valid: boolean;
    value: moment.Moment | null;
  }) => {
    setNewFhwaDueDate(moment.tz(input.value, timeZone));
  };

  return (
    <div>
      {trailer.latestFHWA?.dueDate ? (
        <div>
          {moment.tz(trailer.latestFHWA.dueDate, timeZone).format(dateFormat)}
          <EditIcon
            icon={EditSharp}
            type='subtle'
            size='SM'
            onClick={handleOpenModal}
          />
        </div>
      ) : (
        <AddLink
          type='link'
          onClick={handleOpenModal}
          data-el={'Mappings-TrailersInfo-EditableFHWACell-AddLink'}
        >
          {strings.addButtonText}
        </AddLink>
      )}

      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        title={`${strings.titlePrefix} ${trailer.number}`}
        actionButtonFunction={handleOnSave}
        isActionButtonDisabled={
          !newFhwaDueDate ||
          newFhwaDueDate.toISOString() === defaultDueDate?.toISOString()
        }
        dataElPrefix={'Mappings-TrailersInfo-EditableFHWACell-Modal'}
      >
        <Fuel.Label
          label={strings.inputLabel}
          data-el={'Mappings-TrailersInfo-EditableFHWACell-Modal-InputLabel'}
        >
          <Fuel.Input.Date
            placeholder={strings.inputPlaceHolderText}
            value={newFhwaDueDate ?? null}
            onChangeDate={handleOnDateChanged}
            suffix={<Fuel.Icon icon={CalendarTodaySharp} />}
            format={dateFormat}
            data-el={'Mappings-TrailersInfo-EditableFHWACell-Modal-InputDate'}
          />
        </Fuel.Label>
      </Modal>
    </div>
  );
};

EditableFHWACell.createFhwaDueDateMutation = gql`
  mutation createTrailerServiceRequest(
    $input: CreateTrailerServiceRequestsInput!
  ) {
    createTrailerServiceRequests(input: $input) {
      createdServiceRequests {
        id
        type
        trailerKey
        dueDate
      }
    }
  }
`;

export default EditableFHWACell;
