import * as GraphQL from '~/services/apollo';
import {
  MutationResult,
  TrailerExternalReference,
  TrailerInfoFragment,
  UpdateExternalReferenceMutation,
  UpdateTrailerExternalReferenceInput,
} from '~/services/apollo';

// custom hook - returns a function that will perform the update
const useUpdateExternalReference = (): [
  (params: {
    newExternalId: string;
    trailer: TrailerInfoFragment;
  }) => Promise<TrailerExternalReference>,
  MutationResult<UpdateExternalReferenceMutation>,
] => {
  const [updateFunction, statusFields] =
    GraphQL.useUpdateExternalReferenceMutation();
  // newExternalId - the new trailer externalId
  // trailer - the trailer the new externalId is for
  // returns the updatedExternalReference
  const returnFunction = async (params: {
    newExternalId: string;
    trailer: TrailerInfoFragment;
  }) => {
    const { trailer, newExternalId } = params;
    if (
      !trailer.trailerExternalReferences.length ||
      trailer.trailerExternalReferences.length !== 1
    ) {
      throw new Error(
        `There is no existing trailerExternalReference to update for trailer ${JSON.stringify(
          trailer,
        )}. Please call useCreateExternalReference instead.`,
      );
    }
    const updateInput: UpdateTrailerExternalReferenceInput = {
      id: trailer.trailerExternalReferences![0]!.id,
      updateFields: {
        externalId: newExternalId,
      },
    };
    const { data } = await updateFunction({
      variables: {
        input: updateInput,
      },
    });
    if (!data?.updateTrailerExternalReference.trailerExternalReference) {
      throw new Error(
        `Error updating external reference for request ${JSON.stringify(
          updateInput,
        )}`,
      );
    }
    return data!.updateTrailerExternalReference.trailerExternalReference;
  };
  return [returnFunction, statusFields];
};

export default useUpdateExternalReference;
