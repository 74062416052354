import styled from '@emotion/styled';

import * as Fuel from '@convoy/fuel';

export { default as SortIcon } from './SortIcon';

export const Table = styled(Fuel.Table)({
  ...Fuel.Text.Size.SM,
});

export const TableHeader = styled(Fuel.Table.Header)({
  backgroundColor: Fuel.Color.Light.N300,
  fontWeight: 'normal',
  // Add padding to the left side of the first header cell
  '>div:nth-of-type(1)': {
    paddingLeft: Fuel.Unit.MD,
  },
});

export const HeaderCell = styled(Fuel.Table.Col)({
  display: 'flex',
  paddingTop: Fuel.Unit.SM,
  paddingBottom: Fuel.Unit.SM,
  alignItems: 'center',
});

export const TableBody = styled(Fuel.Table.Body)({});

export const TableRow = styled(Fuel.Table.Row)({
  // Add padding to the left side of the first cell in each body row
  '>div:nth-of-type(1)': {
    paddingLeft: Fuel.Unit.MD,
  },
});

export const BodyCell = styled(Fuel.Table.Col)({
  display: 'flex',
  paddingTop: Fuel.Unit.LG,
  paddingBottom: Fuel.Unit.LG,
  alignItems: 'center',
});
