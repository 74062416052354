import TrailersInfo from '~/components/pages/mappings/TrailersInfo';
import * as GraphQL from '~/services/apollo';
import {
  TrailerInfoFragment,
  MutationResult,
  FhwaFragment,
  CreateTrailerServiceRequestsInput,
  ServiceRequestType,
  CreateTrailerServiceRequestMutation,
} from '~/services/apollo';

const useCreateFHWA = (): [
  (params: {
    newFhwa: Date;
    trailer: TrailerInfoFragment;
  }) => Promise<FhwaFragment>,
  MutationResult<CreateTrailerServiceRequestMutation>,
] => {
  const [createFunction, statusFields] =
    GraphQL.useCreateTrailerServiceRequestMutation();
  const returnFunction = async (params: {
    newFhwa: Date;
    trailer: TrailerInfoFragment;
  }): Promise<FhwaFragment> => {
    const { newFhwa, trailer } = params;
    const input: CreateTrailerServiceRequestsInput = {
      serviceRequests: [
        {
          trailerKey: trailer.key!,
          type: ServiceRequestType.FhwaInspection,
          dueDate: newFhwa,
          createdAt: newFhwa,
        },
      ],
    };

    const { data } = await createFunction({
      variables: {
        input,
      },
      update(cache, { data }) {
        cache.modify({
          id: cache.identify(trailer),
          fields: {
            latestFHWA() {
              const newLatestFHWARef = cache.writeFragment({
                data: data?.createTrailerServiceRequests
                  .createdServiceRequests[0],
                fragment: TrailersInfo.FHWAFragment,
              });
              return newLatestFHWARef;
            },
          },
        });
      },
    });

    if (
      !data?.createTrailerServiceRequests.createdServiceRequests.length ||
      data.createTrailerServiceRequests.createdServiceRequests.length !== 1
    ) {
      throw new Error(
        `Error creating new FHWA for request ${JSON.stringify(input)}`,
      );
    }
    return data.createTrailerServiceRequests.createdServiceRequests[0];
  };
  return [returnFunction, statusFields];
};

export default useCreateFHWA;
